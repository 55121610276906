<template>
    <div
        id="creator-modal"
        class="modal modal-lg fade"
        tabindex="-1"
        aria-hidden="true"
        aria-labelledby=""
    >
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <img
                        src="/images/logo-form-modal.svg"
                        role="presentation"
                        class="form-modal-image"
                    />
                    <button type="button" class="btn-close form-modal-header-close btn-body-tertiary" data-bs-dismiss="modal" aria-label="Close"></button>
                    <p class="form-modal-header-title">
                        <h4 class="fw-bold">{{ $t('user.menu.selectAnIkon') }}</h4>
                        <small class="fs-7 fw-light">{{ $t('user.menu.followedIkon') }} <span class="text-decoration-underline fs-7 fw-bold text-primary">{{ selectedCreator?.username }}</span></small>
                    </p>
                </div>
                
                <div class="row bg-light rounded-3 py-3">
                    <div
                        v-for="subscribedCreator in subscribedCreators?.filter(
                            (creator) => creator.id !== selectedCreator?.id
                        )"
                        :key="subscribedCreator.id"
                        class="col"
                    >
                        <a
                            href="#"
                            class="creator-view-item selectable d-flex px-2 py-2 mx-2 rounded justify-content-center align-items-center text-decoration-none d-block"
                            @click.prevent="selectCreator(subscribedCreator)"
                        >
                            <div class="text-center">
                                <div class="avatar-cropper">
                                    <img
                                        :src="
                                            subscribedCreator.avatar !== ''
                                                ? subscribedCreator.avatar
                                                : 'https://ui-avatars.com/api/?name=' +
                                                    subscribedCreator.username +
                                                    '.png&background=random&size=100'
                                        "
                                        class="avatar-pic"
                                    />
                                </div>
                                <br>
                                <span class="fs-7 text-dark">{{ subscribedCreator.username }}</span>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { computed } from 'vue'
import router from "@/router";
import { useI18n } from 'vue-i18n'
import { useUserStore } from '@/stores/user'
import NotificationService from '@/services/NotificationService'
import {useCreatorStore} from "@/stores/frontend/creator";

/** Locale **/
const { t } = useI18n()

/** Stores **/
const userStore = useUserStore()

/** Init parameters **/
const subscribedCreators = computed(
    () => userStore.user.creators_subscribed_to || []
)
const selectedCreator = computed(() => userStore.selectedCreator)

const creatorStore = useCreatorStore()

/** Methods **/
async function selectCreator(_creator, notify = true) {
    // hidden modal to prevent updating content
    let creatorModal = document.getElementById('creator-modal')
    creatorModal.style.display = 'none'
    // Change creator

    let creator = await creatorStore.findCreatorBySlug(_creator.slug)
    userStore.setSelectedCreator(creator)


    // Redirect to reload content
    await router.push({
        name: 'user-dashboard-with-slug',
        params: {
            creatorSlug: creator.slug
        }
    })
    await router.go(0)
    // Notification message
    NotificationService.notifyUserSuccess(
        t('user.menu.creator.changeSuccess', { name: creator.full_name })
    )
}
</script>

<style>
.avatar-cropper {
    position: relative;
    width: 100px;
    height: 100px;
    overflow: hidden;
    border-radius: 50%;
}
.avatar-pic {
    object-fit: cover;
    width: 100% !important;
    height: 100% !important;
}

</style>