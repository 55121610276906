<template>
    <div class="modal-content video-modal">
        <div class="modal-header">
            <div class="row info-header">
                <div class="col text-end">
                    <button class="btn btn-outline-tertiary rounded-pill close-modal" @click="hideModal">
                        {{ $t('globals.exit') }}
                    </button>
                </div>
            </div>
        </div>
        <div class="modal-body">
            <div class="d-inline-block w-100 h-100">
                <VideojsPlayer
                    v-if="
                        item.content !== '' &&
                        item.content !== 'processing' &&
                        item.content !== 'pending' &&
                        item.content !== 'failed'
                    "
                    ref="playerRef"
                    :key="item.episode_id"
                    :options="videoOptions"
                    :autoplay="false"
                    @video-playing="videoPlaying"
                />
                
                <div class="waiting" :class="{ loading: loading }">
                    <i class="fa-solid fa-spinner fa-spin-pulse"></i>
                </div>
            </div>
            <div
                v-if="item.extra?.prevEpisode || item.extra?.nextEpisode"
                v-show="showOverlay"
                class="general-overlay"
                :class="{ show: showOverlay }"
            >
                <div class="position-absolute episode-overlay-row w-100">                        
                    <div v-if="item.extra?.prevEpisode" class="episode-overlay-left">
                        <a
                            v-if="item.extra?.prevEpisode"
                            href="#"
                            class="text-decoration-none"
                            @click="playPrev"
                        >
                            <div class="d-flex align-items-center">
                                <div class="prev-button me-3 text-white">
                                    <i class="fa fa-backward-step"></i>
                                </div>
                                <div class="prevTitle text-truncate text-white" :class="($breakpoints.smAndDown.value) ? 'fs-7' : 'fs-5'">
                                    <span class="episode-title fw-bold">
                                        {{ TranslationsHelper.getLocalisedContent(item.extra.prevEpisode.title, currentLocale) }}
                                    </span>
                                    <br>
                                    {{ $t('user.workouts.episodes.label') }}
                                    {{ item.extra.prevEpisode.episode_number }}
                                    -
                                    {{ $filters.formatDuration(item.extra.prevEpisode.total_duration_in_seconds) }}
                                </div>
                            </div>
                        </a>
                    </div>
                    <div class="episode-overlay-right">
                        <a
                            v-if="item.extra?.nextEpisode"
                            href="#"
                            class="text-decoration-none"
                            @click="playNext"
                        >
                            <div v-if="item.extra?.nextEpisode" class="d-flex align-items-center">                                
                                <div class="prevTitle text-truncate text-white text-end" :class="($breakpoints.smAndDown.value) ? 'fs-7' : 'fs-5'">
                                    <span class="episode-title fw-bold">
                                        {{ TranslationsHelper.getLocalisedContent(item.extra.nextEpisode.title, currentLocale) }}
                                    </span>
                                    <br>
                                    {{ $t('user.workouts.episodes.label') }}
                                    {{ item.extra.nextEpisode.episode_number }}
                                    -
                                    {{ $filters.formatDuration(item.extra.nextEpisode.total_duration_in_seconds) }}
                                </div>
                                <div class="next-button ms-3 text-white">
                                    <i class="fa fa-forward-step"></i>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>                    
            </div>
        </div>
        <div class="modal-footer">
            <div class="content">
                <h3 v-if="item.title">
                    {{ item.title }}
                </h3>
                <p v-if="item.subtitle">
                    {{ item.subtitle }}
                </p>
            </div>
        </div>
    </div>
</template>

<script setup>
import { onBeforeMount, ref, watch } from 'vue'
import { useViewModalStore } from '@/stores/viewModal'
import i18n from '@/translation'
import VideojsPlayer from '@/components/VideojsPlayer.vue'
import TranslationsHelper from '@/helpers/TranslationsHelper'

const viewModalStore = useViewModalStore()
const currentLocale = i18n.global.locale.value

const props = defineProps({
    item: {
        type: Object,
        required: true,
    },
})

let videoOptions = ref({
    autoplay: true,
    preload: 'auto',
    // fluid: true,
    html5: {
        vhs: {},
    },
    sources: [],
})

const showOverlay = ref(false)

const playerRef = ref(null)

let loading = ref(true)

const videoPlaying = () => {
    loading.value = false
}

onBeforeMount(() => {
    videoOptions.value.sources.push({
        src: props.item.content,
        type: 'application/x-mpegURL',
    })
})

watch(
    () => props.item,
    () => {
        videoOptions.value = {
            ...videoOptions.value,
            sources: [
                {
                    src: props.item.content,
                    type: 'application/x-mpegURL',
                },
            ],
        }
    }
)

const hideModal = () => {
    viewModalStore.hideFormModal()
}

defineExpose({
    hideModal,
})
</script>

<style>
.hidden {
    display: none !important;
}
#form-modal .modal-footer button {
    flex-grow: 1;
    margin: 0.438rem 3.125rem;
}

.video-modal .video-js {
    max-height: 100vh !important;
}

.video-modal .plyr--video {
    position: inherit;
    margin: auto;
    width: 100%;
    height: 100%;
}

.video-modal .vjs-control-bar,
.video-modal .plyr__controls {
    z-index: 2000;
}

.modal-fullscreen .modal-content {
    padding: 0;
    background: black;
}

.modal-fullscreen .modal-header {
    padding: 1.5rem;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 11;
}

.modal-fullscreen .modal-header .close-modal {
    margin-left: auto;
    padding: 0.625rem 2.5rem;
}

.modal-fullscreen .modal-footer {
    position: absolute;
    bottom: 0;
    color: white;
    font-family: Poppins, sans-serif;
    font-size: 1rem;
    background: transparent linear-gradient(359deg, #000000 0%, #54545400 100%) 0% 0% no-repeat padding-box;
    opacity: 1;
    width: 100%;
    z-index: 0;
}

.modal-fullscreen .modal-footer .content {
    padding: 2.438rem 7.563rem;
    width: 100%;
}

.modal-fullscreen .modal-footer h3 {
    font-family: Poppins, sans-serif;
    font-size: 1.5rem;
}
</style>

<style lang="scss" scoped>
.episode-overlay-row {
    z-index: 2000;
    top: 45%;
}
.episode-overlay-left {
    float: left;
    background: #0000007f;
    padding: 1rem;
    
}
.episode-overlay-right {
    float: right;
    background: #0000007f;
    padding: 1rem;
}

@media (max-width: 65px) {
    .episode-overlay-left {
        min-width: 30%;
        max-width: 50%;
    }
    .episode-overlay-right {
        min-width: 30%;
        max-width: 50%;
    }
}

.general-overlay {
    opacity: 0;
}

.general-overlay.show {
    opacity: 1;
}

.general-overlay .episode-overlay {
    z-index: 2002;
}

.waiting {
    position: absolute;
    top: 50%;
    left: 50%;
    color: white;
    width: 200px;
    transform: translate(-50%, -50%);
    align-items: center;
    justify-content: center;
    z-index: 999;
    display: none;
}

.waiting.loading,
.waiting.show {
    display: flex;
}
.info-header {
    width: 100%;
}
</style>