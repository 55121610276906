<template>
    <div
        id="view-modal"
        ref="viewModalRef"
        class="modal modal-md fade"
        tabindex="-1"
        aria-hidden="true"
        aria-labelledby=""
    >
        <div class="modal-dialog" :class="modalStyle">
            <ViewEpisodeModal
                v-if="viewModalStore.modal.type === 'video'"
                ref="viewEpisodeModalRef"
                :item="viewModalStore.modal"
            />
            <ViewWorkoutTipsModal 
                v-else-if="viewModalStore.modal.type === 'tips'" 
                :item="viewModalStore.modal" 
            />
            <ViewRecipeModal 
                v-else-if="viewModalStore.modal.type === 'recipe'" 
                :item="viewModalStore.modal" 
            />
        </div>
    </div>
</template>

<script setup>
import { computed, onBeforeUnmount, onMounted, ref } from 'vue'
import { useViewModalStore } from '@/stores/viewModal'
import { Modal } from 'bootstrap'
import ViewEpisodeModal from '@/components/Modals/ViewEpisodeModal.vue'
import ViewWorkoutTipsModal from '@/components/Modals/ViewWorkoutTipsModal.vue'
import ViewRecipeModal from '@/components/Modals/ViewRecipeModal.vue'

const viewModalStore = useViewModalStore()
let currentModalObject = ref(null)

const viewModalRef = ref(null)
const viewEpisodeModalRef = ref(null)

const modalStyle = computed(() => {
    return ['video', 'recipe'].includes(viewModalStore.modal.type) ? 'modal-fullscreen' : 'modal-lg'
})

onMounted(() => {
    currentModalObject.value = new Modal(viewModalRef.value, {
        keyboard: false, // make the hidePrevented.bs.modal fired on esc key
    })

    viewModalRef.value.addEventListener('hidePrevented.bs.modal', (e) => {
        viewEpisodeModalRef.value?.hideModal().then((shouldClose) => {
            if (!shouldClose) {
                e.preventDefault()
            }
        })
    })

    viewModalStore.onDisplay(() => {
        currentModalObject.value.show()
    })

    viewModalStore.onHide(() => {
        currentModalObject.value.hide()
    })
})

onBeforeUnmount(() => {
    currentModalObject.value?.dispose()
})
</script>
<style lang="scss" scoped>
.episode-overlay-row {
    z-index: 2000;
    top: 45%;
}
.episode-overlay-left {
    float: left;
    background: #0000007f;
    padding: 1rem;
    
}
.episode-overlay-right {
    float: right;
    background: #0000007f;
    padding: 1rem;
}

@media (max-width: 65px) {
    .episode-overlay-left {
        min-width: 30%;
        max-width: 50%;
    }
    .episode-overlay-right {
        min-width: 30%;
        max-width: 50%;
    }
}

.episode-overlay.next-episode-overlay {
    position: absolute;
    top: 40%;
    right: 0;
}

.episode-overlay.prev-episode-overlay {
    position: absolute;
    top: 40%;
    left: 0;
    background: none;
    min-width: 0;
}

.general-overlay {
    opacity: 0;
}

.general-overlay.show {
    opacity: 1;
}

.general-overlay .episode-overlay {
    z-index: 2002;
}

.waiting {
    position: absolute;
    top: 50%;
    left: 50%;
    color: white;
    width: 200px;
    transform: translate(-50%, -50%);
    align-items: center;
    justify-content: center;
    z-index: 999;
    display: none;
}

.waiting.loading,
.waiting.show {
    display: flex;
}
.info-header {
    width: 100%;
}
.rep-info {
    color: white;
    max-width: 130px;
    text-align: center;
    background-color: black;
    padding: 0.625rem;
    border-radius: 3.125rem;
    align-items: center;
    justify-content: center;
}
.darken-overlay {
    background: rgba(0, 0, 0, 0.6);
}

.waiting-overlay {
    z-index: 2001;
}
</style>