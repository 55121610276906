<template>
    <BaseField
        :id="id"
        :name="name"
        :label="label"
        :error-message="errorMessage"
        :required="required"
    >
        <textarea
            :id="id"
            class="form-control form-control-modal"
            :class="[{ 'is-invalid': errorMessage }]"
            :name="name"
            :value="value"
            :placeholder="placeholder"
            :rows="props.rows"
            @input="handleChange"
            @blur="handleBlur"
        ></textarea>
        <div v-if="max !== null" class="d-flex">
            <div class="flex-grow-1"></div>
            <TextCharacterCounter :current-length="value.length" :max="max" class="small my-2"/>
        </div>
    </BaseField>
</template>

<script setup>
import BaseField from '@/components/Modals/Fields/BaseField.vue'
import { toRef } from 'vue'
import { useField } from 'vee-validate'
import TextCharacterCounter from '@/components/TextCharacterCounter.vue'

const props = defineProps({
    id: {
        type: String,
        default: '',
    },
    type: {
        type: String,
        default: 'text',
    },
    name: {
        type: String,
        required: true,
    },
    placeholder: {
        type: String,
        default: '',
    },
    label: {
        type: String,
        default: '',
    },
    errorMessage: {
        type: String,
        default: '',
    },
    rules: {
        type: Object,
        default: () => {},
    },
    rows: {
        type: Number,
        default: 3,
    },
    required: {
        type: Boolean,
        default: false,
    },
    max: {
        type: Number,
        default: null,
    },
})

const name = toRef(props, 'name')
const { value, errorMessage, handleBlur, handleChange } = useField(
    name,
    props.rules
)
</script>
