<template>
    <RouterView v-if="!useLoadingStore().loginOut" />

    <SwitchCreatorModal />
    <GalleryCommentModal />
    <ViewModal />
    <FormModal />
    <Notification />
    <ShareSocialModal />
    <CtaRegistrationModal/>
</template>

<script setup>
import { RouterView } from 'vue-router'
import FormModal from '@/components/Modals/FormModal.vue'
import SwitchCreatorModal from '@/components/Modals/SwitchCreatorModal.vue'
import GalleryCommentModal from '@/components/Modals/GalleryCommentModal.vue'
import ViewModal from '@/components/Modals/ViewModal.vue'
import Notification from '@/components/Notification.vue'
import CtaRegistrationModal from "@/components/Modals/CtaRegistrationModal.vue";
import {useLoadingStore} from "@/stores/frontend/loading";
import ShareSocialModal from "@/components/Modals/ShareSocialModal.vue";

import { detectIncognito } from "detectincognitojs";
import {onBeforeMount, onBeforeUnmount, onMounted} from "vue";
import SWService from "@/services/SWService";
import { useI18n } from 'vue-i18n'

import { useNavigatorLanguage } from '@/plugins/Composables/useNavigatorLanguage'
const { setLanguage } = useNavigatorLanguage()

const {t} = useI18n()

let updateChecker = null;

onBeforeMount(() => {
    setLanguage()
})

onMounted(() => {
    SWService.checkAvailability()
    .then(() => {
        return detectIncognito()
    })
    .then((result) => {
        if (!result.isPrivate) {
            return SWService.SWRegistration(t)
        }
        return Promise.reject('private')
    })
    .then(() => {
        updateChecker = setInterval(async () => {
            return SWService.registerServiceWorker(t)
        }, 10 * 60 * 1000); // every 5 minutes check update
    })
    .catch((e) => {
        if (e !== 'private') {
            console.log(e)

        }
    })
})

onBeforeUnmount(() => {
    clearInterval(updateChecker)
})


</script>