<template>
    <div
        :id="modalId"
        class="modal modal-md fade"

        :class="{ show: active, 'd-block': active }"
        tabindex="-1"

        aria-hidden="true"
        aria-labelledby=""
    >
        <div class="modal-dialog">
            <div class="modal-content p-4">
                <div class="modal-header text-center">
                    <img
                        src="/images/logo-form-modal.svg"
                        role="presentation"
                        class="form-modal-image"
                    />
                    <!-- <button class="form-modal-header-close" @click="hideModal">
                        <img
                            src="../../assets/images/close-icon.svg"
                            alt="Close"
                        />
                    </button> -->
                    <button type="button" class="btn-close form-modal-header-close btn-body-tertiary" data-bs-dismiss="modal" aria-label="Close"></button>
                    <p v-show="!userStore.user.is_creator" class="grazler fs-4 text-center">
                        {{ $t('creator.payment.form.join') }}
                    </p>

                    <i18n-t v-show="!userStore.user.is_creator" tag="p" class="" keypath="creator.payment.form.joinHelper" scope="global">
                        <template #creator>
                            {{$filters.capitalized(userStore.selectedCreator.username)}}
                        </template>
                        <template #br>
                            <br>
                        </template>
                    </i18n-t>
                </div>

                <div class="modal-body px-sm-2 px-md-4">
                    <div v-if="formType === 'register' && !userStore.user.is_creator"  class="">
                        <Form
                            ref="registerForm"
                            :validation-schema="validationSchema"
                            @submit="submitRegister"
                            @invalid-submit="onInvalidSubmit"
                        >
                            <div class="d-flex flex-column gap-3">
                                <div class="form-group form-floating mb-1">
                                    <BaseInput
                                        id="username"
                                        required big
                                        name="username"
                                        type="text"
                                        :white-bg="false"
                                        :placeholder="$t('creator.payment.form.username.placeholder')"
                                        :label="$t('creator.payment.form.username.placeholder')"
                                    />
                                </div>
                                <div class="form-group form-floating mb-1">
                                    <BaseInput
                                        id="registerEmail"
                                        required big
                                        name="email"
                                        type="email"
                                        :white-bg="false"
                                        :placeholder="$t('creator.payment.form.email.placeholder')"
                                        :label="$t('creator.payment.form.email.placeholder')"
                                    />
                                </div>
                                <!-- <div class="form-group form-floating mb-1">
                                    <BaseInput
                                        id="firstname"
                                        required big
                                        name="firstname"
                                        type="text"
                                        :white-bg="false"
                                        :placeholder="$t('creator.payment.form.firstName.placeholder')"
                                        :label="$t('creator.payment.form.firstName.placeholder')"
                                    />
                                </div>
                                <div class="form-group form-floating mb-1">
                                    <BaseInput
                                        id="lastname"
                                        required big
                                        name="lastname"
                                        type="text"
                                        :white-bg="false"
                                        :placeholder="$t('creator.payment.form.lastName.placeholder')"
                                        :label="$t('creator.payment.form.lastName.placeholder')"
                                    />
                                </div> -->
                                <div class="form-group form-floating mb-1">
                                    <BaseSelect
                                        id="country_id"
                                        name="country_id"
                                        required big
                                        :options="countries"
                                        :light="true"
                                        :placeholder="$t('creator.payment.form.country.placeholder')"
                                        :label="$t('creator.payment.form.country.placeholder')"
                                        is-object
                                        option-text="name"
                                        option-value="id"
                                        :model-value="preselectedCountry"
                                        @update:model-value="updateCountry"

                                    />
                                </div>
                                <!-- <div class="form-group form-floating">
                                    <BaseSelect
                                        id="cta-timezone"
                                        name="timezone"
                                        required big
                                        :options="timezones"
                                        :light="true"
                                        :placeholder="$t('creator.onboarding.form.step3.timezone')"
                                        :label="$t('creator.onboarding.form.step3.timezone')"
                                        :model-value="preselectedTimezone"
                                        is-array
                                    />
                                </div> -->
                                <div class="form-group form-floating mb-0">
                                    <div class="form-check">
                                        <CheckboxV2
                                            id="tcs"
                                            :value="true"
                                            name="tcs"
                                            :label="`creator.payment.form.tcs`"
                                            :link-label="`creator.payment.global.links.termsUse`"
                                            :route-label="`static-en-gtcu`"
                                            required
                                        />
                                    </div>
                                </div>
                                <!--                                    <div class="col-12 col-lg-12 mt-2">-->
                                <!--                                        <input-->
                                <!--                                            id="postal_code"-->
                                <!--                                            v-model="guest.postal_code"-->
                                <!--                                            name="postal_code"-->
                                <!--                                            required-->
                                <!--                                            class="form-control"-->
                                <!--                                            type="text"-->
                                <!--                                            :placeholder="$t('creator.payment.form.postalCode.placeholder')"-->
                                <!--                                            autocomplete="postal_code"-->
                                <!--                                        />-->
                                <!--                                    </div>-->
                                <div class="col-12 mt-0 text-center fs-6 cursor-pointer link capitalize" @click="switchLogin">
                                    {{ $t('auth.login.switch.login') }}
                                </div>
                                <button class="btn btn-primary btn-lg" type="submit" :disabled="loadingStore.loading">
                                    <template v-if="loadingStore.loading">
                                        <span class="spinner-border spinner-border-sm" aria-hidden="true"></span>
                                        <span class="visually-hidden" role="status">Loading...</span>
                                    </template>
                                    {{ $t('creator.payment.form.button.join') }}
                                </button>
                            </div>
                        </form>
                    </div>
                    <div v-if="formType === 'login' && !userStore.user.is_creator">

                        <LoginForm no-forgot from-cta></LoginForm>

                        <div class="col-12 mt-4 text-center fs-6 cursor-pointer link" @click="switchRegister">
                            {{ $t('auth.login.switch.register') }}
                        </div>
                    </div>
                    <div v-if="userStore.user.is_creator">
                        <h2 class="text-center mt-4">
                            STAY TUNED
                        </h2>
                        <p class="fs-5 text-center mt-3">
                            As a creator, you will soon be able to subscribe to other content creators
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import {computed, onBeforeMount, onMounted, ref} from "vue";
import {Form} from "vee-validate";
import {Modal} from "bootstrap";
import i18n from '@/translation'
import { object, string, boolean } from 'yup'
import router from "@/router";
import {useRegistrationCtaModalStore} from "@/stores/registrationCtaModal";
import {useLoadingStore} from "@/stores/frontend/loading";
import {useSubscriptionStore} from "@/stores/frontend/subscription";
import {useUserStore} from "@/stores/user";
import ApiService from "@/services/ApiService";
import TimezoneHelper from "@/helpers/TimezoneHelper";
import BaseInput from "@/components/Form/BaseInput.vue";
// import BaseCheckbox from "@/components/Form/BaseCheckbox.vue";
// import TestCheckbox from "@/components/Form/CheckboxV2.vue";
import CheckboxV2 from "@/components/Form/CheckboxV2.vue";
import BaseSelect from "@/components/Form/BaseSelect.vue";
import LoginForm from "@/views/Auth/LoginForm.vue";
import {useGlobalSettingsStore} from "@/stores/globalSettings";
// import {useCreatorStore} from "@/stores/frontend/creator";

import {event as GtagEvent} from 'vue-gtag'

/** Locale */
const { t } = i18n.global

const props = defineProps({
    id: {
        type: String,
        default: () => (crypto.randomUUID())
    }
})

/* Stores */
const ctaModalStore = useRegistrationCtaModalStore()
const loadingStore = useLoadingStore()
const subscriptionStore = useSubscriptionStore()
const userStore = useUserStore()

const formType = ref('register')

/** Init parameters **/
const currentModalObject = ref(null)
const active = ref(false)
const modalId = computed(() => `${props.id}-modal`)
const timezones = ref([])
const preselectedTimezone = ref(null)
const guest = ref({
    firstname: '',
    lastname: '',
    username: '',
    email: '',
    postal_code: '',
    country_id: '',
    password: '',
    passwordConfirm: '',
})
const countries = ref([])
const preselectedCountry = ref(null)
const registerForm = ref(null)

/** Validation **/
const validationSchema = computed(() => {
    return object({
        username: string().required().label(t('creator.payment.form.username.placeholder')),
        email: string().email().required().label(t('creator.payment.form.email.placeholder')),
        // firstname: string().required().label(t('creator.payment.form.firstName.placeholder')),
        // lastname: string().required().label(t('creator.payment.form.lastName.placeholder')),
        tcs: boolean().required(t('creator.payment.form.tcsRequired')),
        // timezone: string()
        //         .typeError(t('creator.onboarding.invalidTimezone'))
        //         .required(t('creator.onboarding.requiredTimezone'))
        //         .oneOf(
        //             Object.values(timezones.value),
        //             t('creator.onboarding.invalidTimezone')
        //         )
        //         .not(['not-selected']),
    })
})

/** Methods **/

// Handle and updte country id
function updateCountry(countryId) {
    guest.value.country_id = countryId
    preselectedCountry.value = countryId
}

// Handle errors form
function onInvalidSubmit({ errors }) {
    // console.log("errors, ", errors)
}

// Handle and submit the form
function submitRegister(values) {
    loadingStore.setLoading(true)

    let langId = useGlobalSettingsStore().availableLanguages.find((_lang) => _lang.code === userStore.currentLang)

    if (!langId || langId === null || langId === undefined) {
        langId = {
            id: 38
        }
    }

    subscriptionStore
        .registerGuest({
            ...values,
            // name: `${values.firstname} ${values.lastname}`,
            creator_id: userStore.selectedCreator.id,
            timezone: preselectedTimezone.value,
            preferred_language_id: langId.id
        })
        .then((user) => {
            userStore.setAuth(user)

            GtagEvent('register');

            // if (parseInt(useCreatorStore().creator.id) === parseInt(import.meta.env.VITE_CREATOR_NO_SUB)) {
            //     currentModalObject.value.hide()
            //     router.push({
            //         name: 'payment',
            //         params: {
            //             creatorSlug: useCreatorStore().creator.slug
            //         }
            //     })
            // }
            // else {
                localStorage.setItem('from-registration', 'true')

                router.go(0)
            // }
        })
        .finally(() => {
            loadingStore.setLoading(false)
        })
}

function switchLogin(){
    formType.value = 'login'
}

function switchRegister(){
    formType.value = 'register'
}


onBeforeMount(() => {
    loadingStore.setLoading(true)

    ApiService.get('/countries').then(({data}) => {
        countries.value = [...data]

        preselectedCountry.value = TimezoneHelper.getCountryFromTimezone(countries.value)?.id
        guest.value.country_id = preselectedCountry.value
        loadingStore.setLoading(false)
    })
    preselectedTimezone.value = TimezoneHelper.getUserTimezone();
    timezones.value = [...TimezoneHelper.getAllTimeZones()]
})

onMounted(() => {
    currentModalObject.value = new Modal(`#${modalId.value}`)

    // Show modal
    ctaModalStore.onDisplay(() => {
        currentModalObject.value.show()
    })

    // Hide modal
    ctaModalStore.onHide(() => {
        currentModalObject.value.hide()
    })
})

</script>
