<template>
    <BaseField
        :id="id"
        :name="name"
        :label="label"
        :error-message="errorMessage"
        :required="required"
    >
        <input
            :id="id"
            v-model="value"
            type="checkbox"
            class="form-check-input"
            role="switch"
            :class="[{ 'is-invalid': errorMessage }]"
            :name="name"
            @blur="handleBlur"
        />
    </BaseField>
</template>

<script setup>
import BaseField from '@/components/Modals/Fields/BaseField.vue'
import { toRef } from 'vue'
import { useField } from 'vee-validate'

const props = defineProps({
    id: {
        type: String,
        default: '',
    },
    name: {
        type: String,
        required: true,
    },
    label: {
        type: String,
        default: '',
    },
    errorMessage: {
        type: String,
        default: '',
    },
    rules: {
        type: Object,
        default: () => {},
    },
    required: {
        type: Boolean,
        default: false,
    },
})

const name = toRef(props, 'name')
const { value, errorMessage, handleBlur } = useField(name, props.rules)
</script>
