const options = {
    root: null,
    rootMargin: "0px",
    threshold: 1,
}

const VPauseOnHide = {
    mounted: function (el, binding) {
        const value = binding.value
        const { handler, active } = typeof value === 'object'
            ? value
            : { handler: value, active: false }

        if (active) {
            const observer = new IntersectionObserver((entries = [], observer) => {
                const _observe = el._observe?.[binding.instance.$.uid]
                if (!_observe) return // Just in case, should never fire

                const isIntersecting = entries.some((entry) => {
                    return entry.isIntersecting
                })

                if (handler && !isIntersecting) {
                    handler(isIntersecting, entries, observer)
                }
                else _observe.init = true
            }, options)

            el._observe = Object(el._observe)
            el._observe[binding.instance.$.uid] = { init: false, observer }

            observer.observe(el)
        }
    },
    unmounted: function (el, binding) {
        const observe = el._observe?.[binding.instance.$.uid]
        if (!observe) return

        observe.observer.unobserve(el)
        delete el._observe[binding.instance.$.uid]
    }
}

export default VPauseOnHide