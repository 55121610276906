<template>
    <div id="socialModal" class="modal fade" tabindex="-1" aria-labelledby="socialModalLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content px-3">
                <div class="modal-header">
                    <h2 id="socialModalLabel" class="mb-4 fs-3">
                        <img
                            src="/images/logo-form-modal.svg"
                            role="presentation"
                            class="img-fluid me-1"
                            style="max-width: 25px;"
                        />
                        {{ $t('globals.share.title') }}

                    </h2>
                    <button class="form-modal-header-close " data-bs-dismiss="modal" aria-label="Close">
                        <img src="@/assets/images/close-icon.svg" alt="Close" />
                    </button>
                </div>
                <div class="modal-body">
                    <!-- Socials -->
                    <div class="row justify-content-start">
                        <div v-for="(network, index) in networks" :key="index" :class="$breakpoints.smAndDown.value ? 'col-3 mb-3' : 'col mb-3'">
                            <span class="cursor-pointer" @click.prevent="shareLink(network.url)">
                                <div class="text-white rounded-5 text-center mx-auto" :style="`background-color: ${network.color}; width: 45px; height: 45px; line-height: 45px;`">
                                    <i :class="network.icon" ></i>
                                </div>
                                <div class="mt-1 text-center">
                                    {{ network.name }}
                                </div>
                            </span>
                        </div>
                    </div>
                    <!-- Link -->
                    <div class="mt-4">
                        <div v-if="!shareStore.shareContent.link" class="text-center">
                            <span class="spinner-border spinner-border-sm" aria-hidden="true"></span>
                            <span class="visually-hidden" role="status">Loading...</span>
                        </div>
                        <div v-else class="input-group mb-3">
                            <input type="text" class="form-control" :value="shareStore.shareContent.link" readonly>
                            <button id="button-addon2" class="btn btn-secondary" type="button" @click="copy(shareStore.shareContent.link)">
                                <i class="fa-regular fa-copy me-2"></i>
                                {{ $t('globals.share.copy') }}
                            </button>
                        </div>
                        <div v-if="linkCopied" class="text-info text-center">
                            {{ $t('globals.share.success') }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import i18n from '@/translation'

import { useCreatorStore } from '@/stores/frontend/creator';
import { useShareStore } from '@/stores/share';

import VueFilters from '@/helpers/VueFilters'
import TranslationsHelper from '@/helpers/TranslationsHelper'
import { Modal } from 'bootstrap'

/** Locale **/
const { t } = useI18n()
const currentLocale = i18n.global.locale.value

/** Stores **/
const creatorStore = useCreatorStore();
const shareStore = useShareStore();

/** Init parameters **/
let currentModalObject = null
let link = ref(null)
let linkCopied = ref(false)
let socialLink = ref(null)
const networks = {
    twitter: { url:'https://twitter.com/intent/tweet?text=@t&url=@u&hashtags=@h', network: 'twitter', name: 'X (Twitter)', icon: 'fab fah fa-lg fa-x-twitter', color: '#000000' },
    facebook: { url:'https://www.facebook.com/sharer/sharer.php?u=@u&title=@t&description=@d&quote=@q&hashtag=@h', network: 'facebook', name: 'Facebook', icon: 'fab fah fa-lg fa-facebook-f', color: '#1877f2' },
    whatsapp: { url:'https://api.whatsapp.com/send?text=@t%0D%0A@u%0D%0A@d', network: 'whatsapp', name: 'Whatsapp', icon: 'fab fah fa-lg fa-whatsapp', color: '#25d366' },
    email: { url:'mailto:?subject=@t&body=@u%0D%0A@d', network: 'email', name: 'Email', icon: 'far fah fa-lg fa-envelope', color: '#333333' },
    sms: { url:'sms:?body=@t%0D%0A@u%0D%0A@d', network: 'sms', name: 'SMS', icon: 'far fah fa-lg fa-comment-dots', color: '#333333' },
}

/** Methods **/
// Copy generated link to clipboard
function copy(_link) {
    // Success
    linkCopied.value = true
    // copy link in clipboard
    navigator.clipboard.writeText(_link);
    // Hide alert
    setTimeout(function(){ linkCopied.value = false }, 3000);
}

// Create social link to share
function shareLink(_socialLink) {
    
    socialLink.value = _socialLink
    let title = (shareStore.shareContent.creator) 
        ? shareStore.shareContent.data.slug
        : (typeof shareStore.shareContent.data.title === 'object') 
            ? TranslationsHelper.getLocalisedContent(shareStore.shareContent.data.title, currentLocale)
            : (shareStore.shareContent.data.title !== undefined)
                ? shareStore.shareContent.data.title
                : VueFilters.truncateText(shareStore.shareContent.data.content, 30)
    
    let content = (shareStore.shareContent.creator) 
        ? shareStore.shareContent.data.slug
        : (typeof shareStore.shareContent.data.content === 'object') 
            ? TranslationsHelper.getLocalisedContent(shareStore.shareContent.data.content, currentLocale)
            : shareStore.shareContent.data.content

        

    socialLink.value = 
        socialLink.value
            // .replace(/@tu/g, '&via=' + encodeURIComponent(creatorStore.creator.slug))
            .replace(/@u/g, encodeURIComponent(shareStore.shareContent.network_link)) //link
            .replace(/@t/g, encodeURIComponent(title)) // title
            .replace(/@d/g, encodeURIComponent(content)) // description
            .replace(/@q/g, '') // quote ?
            .replace(/@h/g, '') // hastags
            .replace(/@m/g, '') // media

            
    // Open new window
    window.open(socialLink.value, '_blank')
    // let width = window.innerWidth || (document.documentElement.clientWidth || window.screenX)
    // let height = window.innerHeight || (document.documentElement.clientHeight || window.screenY)
    // let systemZoom = 1
    // let popupLeft = (width - 626) / 2 / systemZoom + (window.screenLeft !== undefined ? window.screenLeft : window.screenX)
    // let popupTop = (height - 436) / 2 / systemZoom + (window.screenTop !== undefined ? window.screenTop : window.screenY)
    
    // window.open(
    //     socialLink.value,
    //     "social-share-link",
    //     ',height=' + 436 +
    //     ',width=' + 626 +
    //     ',left=' + popupLeft +
    //     ',top=' + popupTop +
    //     ',screenX=' + popupLeft +
    //     ',screenY=' + popupTop
    // );
}

onMounted(() => {
    currentModalObject = new Modal('#socialModal')

    // Show modal
    shareStore.onDisplay(() => {
        currentModalObject.show()
    })
})

</script>

<style scoped lang="scss">
.hiddenContent {
    display: none;
}
.myBtn {
    display: block;
}
.avatar-cropper {
    position: relative;
    max-width: 48px;
    max-height: 48px;
    overflow: hidden;
    border-radius: 50%;
}
.avatar-pic {
    object-fit: cover;
    width: 100%;
    height: 100%;
}
.comments-wrapper {
    overflow-y: scroll;
    max-height: 600px;
}
</style>