import { Tooltip } from 'bootstrap'

export default {
    mounted(el, binding) {
        el['_B_TOOLTIP'] = new Tooltip(el, binding.value)
    },
    // updated(el, binding) {
    //     if (el['_B_TOOLTIP']) {
    //         el['_B_TOOLTIP'] = new Tooltip(el, binding.value)
    //     }
    // },

    beforeUnmount(el, binding) {
        el['_B_TOOLTIP'].dispose()
    },
}