// Utilities
import { reactive, ref, toRefs, watchEffect } from 'vue'

const defaultDisplayOptions = {
    mobileBreakpoint: 'md',
    thresholds: {
        xs: 0,
        sm: 576,
        md: 768,
        lg: 992,
        xl: 1200,
        xxl: 1400,
    },
}

function debounce(func, wait) {
    let timeout
    return () => {
        if (timeout) {
            clearTimeout(timeout)
        }
        timeout = setTimeout(func, wait)
    }
}

function getClientWidth() {
    return window.innerWidth
}

function getClientHeight() {
    return window.innerHeight
}

function getPlatform() {
    const userAgent = window.navigator.userAgent

    function match(regexp) {
        return Boolean(userAgent.match(regexp))
    }

    const android = match(/android/i)
    const ios = match(/iphone|ipad|ipod/i)
    const cordova = match(/cordova/i)
    const electron = match(/electron/i)
    const chrome = match(/chrome/i)
    const edge = match(/edge/i)
    const firefox = match(/firefox/i)
    const opera = match(/opera/i)
    const win = match(/win/i)
    const mac = match(/mac/i)
    const linux = match(/linux/i)
    const ssr = match(/ssr/i)

    return {
        android,
        ios,
        cordova,
        electron,
        chrome,
        edge,
        firefox,
        opera,
        win,
        mac,
        linux,
        ssr,
    }
}

function createDisplay() {
    const { thresholds, mobileBreakpoint } = defaultDisplayOptions

    const height = ref(getClientHeight())
    const platform = getPlatform()
    const state = reactive({})
    const width = ref(getClientWidth())

    const update = debounce(() => {
        height.value = getClientHeight()
        width.value = getClientWidth()
    }, 200)

    // eslint-disable-next-line max-statements
    watchEffect(() => {
        const xs = width.value < thresholds.sm
        const sm = width.value < thresholds.md && !xs
        const md = width.value < thresholds.lg && !(sm || xs)
        const lg = width.value < thresholds.xl && !(md || sm || xs)
        const xl = width.value < thresholds.xxl && !(lg || md || sm || xs)
        const xxl = width.value >= thresholds.xxl
        const name = xs
            ? 'xs'
            : sm
            ? 'sm'
            : md
            ? 'md'
            : lg
            ? 'lg'
            : xl
            ? 'xl'
            : 'xxl'
        const breakpointValue =
            typeof mobileBreakpoint === 'number'
                ? mobileBreakpoint
                : thresholds[mobileBreakpoint]
        const mobile = !platform.ssr
            ? width.value < breakpointValue
            : platform.android || platform.ios || platform.opera

        state.xs = xs
        state.sm = sm
        state.md = md
        state.lg = lg
        state.xl = xl
        state.xxl = xxl
        state.smAndUp = !xs
        state.mdAndUp = !(xs || sm)
        state.lgAndUp = !(xs || sm || md)
        state.xlAndUp = !(xs || sm || md || lg)
        state.smAndDown = !(md || lg || xl || xxl)
        state.mdAndDown = !(lg || xl || xxl)
        state.lgAndDown = !(xl || xxl)
        state.xlAndDown = !xxl
        state.name = name
        state.height = height.value
        state.width = width.value
        state.mobile = mobile
        state.mobileBreakpoint = mobileBreakpoint
        state.platform = platform
        state.thresholds = thresholds
    })

    window.addEventListener('resize', update, {
        passive: true,
    })

    return { ...toRefs(state), update }
}

export default {
    install: (app, options) => {
        const display = createDisplay()

        app.config.globalProperties.$breakpoints = display
        app.provide('$breakpoints', display)
    },
}