import { createHead } from '@unhead/vue'

const createHeadWithHook = () => {
    return createHead(
        {
            titleTemplate: (title) => {
                if (!title) {
                    return import.meta.env.VITE_APP_NAME
                }

                return `${title} • ${import.meta.env.VITE_APP_NAME}`
            },
            meta: [
                {
                    name: 'description',
                    content: null,
                },
            ],
        },
        {
            hooks: {
                'tags:resolve': (ctx) => {
                    const titleTag = ctx.tags?.find((tag) => tag.tag === 'title')
                    const metaDescriptionTag = ctx.tags?.find(
                        (tag) => tag.tag === 'meta' && tag.props.name === 'description'
                    )

                    if (!titleTag || !metaDescriptionTag) {
                        return
                    }

                    if (!metaDescriptionTag.props.content) {
                        metaDescriptionTag.props.content = `${titleTag.textContent} • ${import.meta.env.VITE_APP_NAME}`
                    }
                },
            },
        }
    )
}

export default createHeadWithHook