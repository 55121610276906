<template>
    <BaseField
        :id="id"
        :name="name"
        :label="label"
        :error-message="errorMessage"
        :required="required"
    >
        <div class="money-field">
            <input
                :id="id"
                type="number"
                class="form-control form-control-modal form-control-money rounded-3"
                :class="[{ 'is-invalid': errorMessage }]"
                :name="name"
                :value="value"
                :placeholder="placeholder"
                step="0.01"
                @input="handleChange"
                @blur="handleBlur"
            />
        </div>
    </BaseField>
</template>

<script setup>
import BaseField from '@/components/Modals/Fields/BaseField.vue'
import { toRef } from 'vue'
import { useField } from 'vee-validate'

const props = defineProps({
    id: {
        type: String,
        default: '',
    },
    name: {
        type: String,
        required: true,
    },
    placeholder: {
        type: String,
        default: '',
    },
    label: {
        type: String,
        default: '',
    },
    errorMessage: {
        type: String,
        default: '',
    },
    rules: {
        type: Object,
        default: () => {},
    },
    required: {
        type: Boolean,
        default: false,
    },
})

const name = toRef(props, 'name')
const { value, errorMessage, handleBlur, handleChange } = useField(
    name,
    props.rules
)
</script>

<style scoped>
.money-field {
    position: relative;
    /*max-width: 8.125rem;*/
}
.form-control-money {
    padding-right: 2.5rem;
}

.money-field:before {
    position: absolute;
    content: '';
    top: 50%;
    right: 1.125rem;
    transform: translateY(-50%);

    font-family: Poppins, sans-serif;
    font-weight: 600;
    font-size: 1.188rem;
    text-align: left;
    color: var(--modal-money-field-prefix-color);
}
</style>