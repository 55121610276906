<template>
    <div class="modal-content">
        <div class="modal-header">
            <img
                src="/images/logo-form-modal.svg"
                role="presentation"
                class="form-modal-image"
            />
            <button class="form-modal-header-close" @click="hideModal">
                <img
                    src="../../assets/images/close-icon.svg"
                    alt="Close"
                />
            </button>
            <p class="form-modal-header-title">
                {{ viewModalStore.modal.title }}
            </p>
        </div>
        <div class="modal-body">
            <div class="row">
                <div class="col-12">
                    <div id="workout-tips">
                        <ol class="list-group-numbered">
                            <li v-for="(tip, key) in item.content" :key="key" class="list-group-item d-flex justify-content-between align-items-start border-bottom pb-4 mb-4">
                                <div class="ms-2 me-auto">
                                    <div class="fw-bold">{{ $t('user.workouts.episodes.tips.label') + ' ' + (key + 1) }}</div>
                                    {{ TranslationsHelper.getLocalisedContent(tip.content, currentLocale) }}
                                </div>
                            </li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer">

            <PrimaryButton type="button" @click="closeModal(true)">
                {{ $t('user.workouts.episodes.tips.done') }}
            </PrimaryButton>

        </div>
    </div>
</template>

<script setup>
import { useViewModalStore } from '@/stores/viewModal'
import i18n from '@/translation'
import PrimaryButton from '@/components/Buttons/PrimaryButton.vue'
import { ref } from 'vue';
import TranslationsHelper from '@/helpers/TranslationsHelper'

const viewModalStore = useViewModalStore()
const currentLocale = i18n.global.locale.value
const props = defineProps({
    item: {
        type: Object,
        required: true,
    },
})

const item = ref(props.item)
function hideModal() {
    viewModalStore.hideFormModal()
}

function closeModal() {
    viewModalStore.hideFormModal()
}
</script>
<style scoped>
#workout-tips .list-group-numbered {
    padding: 0;
}
</style>