<template>
     <div
        id="gallery-comment-modal"
        class="modal modal-lg fade"
        tabindex="-1"
        aria-hidden="true"
        aria-labelledby=""
    >
        <div class="modal-dialog">
            <div 
                :class="(!$breakpoints.mdAndDown.value) ? 'modal-content' : 'modal-content p-4'"
            >
                <div class="modal-header mb-3">
                    <img
                        src="/images/logo-form-modal.svg"
                        role="presentation"
                        class="form-modal-image"
                    />
                    <button type="button" class="btn-close form-modal-header-close btn-body-tertiary" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <!-- Add a comment -->
                <div class="row mt-1 bg-light rounded-3 py-3">
                    <Form
                        ref="myComment"
                        :validation-schema="validationSchema"
                        @submit="submit"
                        @invalid-submit="onInvalidSubmit"
                    >
                        <div class="input-group">
                            <base-input
                                id="content"
                                name="content"
                                type="input"
                                :placeholder="`${t(
                                    'creator.post.form.comment.add'
                                )}`"
                                class="form-control border-0 p-0 m-0"
                            />
                            <span
                                class="input-group-text border-0 rounded-start rounded-5"
                            >
                                <button class="border-0" :disabled="sending">
                                    <i
                                        class="fa-regular fa-paper-plane text-secondary fs-5"
                                    ></i>
                                </button>
                            </span>
                        </div>
                    </Form>
                </div>

                <!-- Comments -->
                <div class="row py-3 mt-2 comments-wrapper">
                    <div v-if="galleryStore.commentsList.comments.length > 0" class="col">
                        
                        <div
                            v-for="(comment, key) in galleryStore.commentsList.comments"
                            :key="comment.id"
                            class="row mb-3"
                            :class="(key === (galleryStore.commentsList.comments.length-1)) ? '' : 'border-bottom border-1'"
                        >
                            <div class="d-flex justify-content-start">
                                <div class="avatar-cropper me-3 d-flex justify-content-center align-items-center flex-shrink-1">
                                    <img
                                        :src="AvatarHelper.getAvatar(comment.user.avatar, comment.user.username)"
                                        class="avatar-pic"
                                        alt="avatar">
                                </div>
                                <div class="commentContent flex-grow-1">
                                    <div class="d-flex flex-column">
                                        <h4 class="fs-6 mb-1">{{ comment.user.name }}</h4>
                                        <small class="text-body-tertiary">{{ $filters.formatDate(comment.created_at, '', 'fromNow') }}</small>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <p class="mt-2">
                                    {{ `${comment.content.slice(0, textTruncate)}` }}<span v-if="comment.content.length > textTruncate" :id="`dots${comment.id}`">...</span><span v-if="comment.content.length > textTruncate" :id="`more${comment.id}`" class="hiddenContent">{{ `${comment.content.slice(textTruncate)}` }}</span>
                                </p>
                                <a 
                                    v-if="comment.content.length > textTruncate"
                                    :id="`myBtn${comment.id}`"
                                    href="#"
                                    class="text-body-tertiary text-decoration-none text-end mt-n3 myBtn"
                                    @click.prevent="toggleText(comment.id)"
                                >
                                    {{ $t('gallery.user.buttonCollapse.more') }}
                                </a>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { useI18n } from 'vue-i18n'
import {onBeforeUnmount, onMounted, onUnmounted, ref} from 'vue'
import { Form } from 'vee-validate'
import { object, string } from 'yup'
import { useUserStore } from '@/stores/user'
import { useGalleryStore } from '@/stores/gallery'
import { useCommentStore } from '@/stores/comment'
import { Modal } from 'bootstrap'
import NotificationService from '@/services/NotificationService'
import AvatarHelper from "@/helpers/AvatarHelper";

/** Locale **/
const { t } = useI18n()

/** Stores **/
const userStore = useUserStore()
const galleryStore = useGalleryStore()
const commentStore = useCommentStore()

/** Init parameters **/
let myComment = ref()
let currentModalObject = null
let textTruncate = 500

/** Validation */
const validationSchema = object({
    content: string()
    .required(t('globals.form.validations.required', {label: t('globals.form.fields.comment.label')}))
    .label(t('globals.form.fields.comment.label'))
    .min(3, t('globals.form.validations.min', {label: t('globals.form.fields.comment.label'), min: 3}))
    .max(254, t('globals.form.validations.max', {label: t('globals.form.fields.comment.label'), max: 254})),
})

/** Methods **/
// Handle errors forms
function onInvalidSubmit({ errors }) {
    // console.log("errors, ", errors)
}

const sending = ref(false)
// Handle submitform
function submit(values) {
    // Already sending ?
    if (sending.value) {
        return;
    }
    // Preview mode?
    if(userStore.user.preview !== undefined && userStore.user.preview) {
        return;
    }

    sending.value = true

    // Send to API
    let data = ref({
        content: values.content,
        user_id: userStore.user.id,
    })

    commentStore
        .addForGallery(galleryStore.commentsList.galleryId, data)
        .then((response) => {
            // Empty input
            myComment.value.resetForm()
            // Load new comment
            galleryStore.commentsList.comments.unshift(response)
            sending.value = false

            return NotificationService.notifyUserSuccess(
                t('creator.post.form.comment.success')
            )
        })
        .catch((error) => {
            NotificationService.notifyUserError(error)
            sending.value = false
        })

}

// Truncate long text
function toggleText(elem) {
    var dots = document.getElementById("dots" + elem);
    var moreText = document.getElementById("more" + elem);
    var btnText = document.getElementById("myBtn" + elem);

    if (dots.style.display === "none") {
        dots.style.display = "inline";
        btnText.innerHTML = t('gallery.user.buttonCollapse.more'); 
        moreText.style.display = "none";
    } else {
        dots.style.display = "none";
        btnText.innerHTML = t('gallery.user.buttonCollapse.less'); 
        btnText.style.display = "none";
        moreText.style.display = "inline";
    }
}


onMounted(() => {
    currentModalObject = new Modal('#gallery-comment-modal')

    // Show modal
    galleryStore.onDisplay(() => {
        currentModalObject.show()
    })
})


</script>

<style scoped lang="scss">
.hiddenContent {
    display: none;
}
.myBtn {
    display: block;
}
.avatar-cropper {
    position: relative;
    max-width: 48px;
    max-height: 48px;
    overflow: hidden;
    border-radius: 50%;
}
.avatar-pic {
    object-fit: cover;
    width: 100%;
    height: 100%;
}
.comments-wrapper {
    overflow-y: scroll;
    max-height: 600px;
}
</style>