function attachedRoot(node){
  /* istanbul ignore next */
  if (typeof node.getRootNode !== 'function') {
    // Shadow DOM not supported (IE11), lets find the root of this node
    while (node.parentNode) node = node.parentNode

    // The root parent is the document if the node is attached to the DOM
    if (node !== document) return null

    return document
  }

  const root = node.getRootNode()

  // The composed root node is the document if the node is attached to the DOM
  if (root !== document && root.getRootNode({ composed: true }) !== document) return null

  return root
}

function handleShadow(el, callback) {
  const root = attachedRoot(el)
  callback(document)

  if (typeof ShadowRoot !== 'undefined' && root instanceof ShadowRoot) {
    callback(root)
  }
}

const VClickOutside = {
    mounted: function (element, binding) {
        let clickOutsideEvent = function (event) {
            let excludeComponent = document.getElementById(binding.arg)
            if (
                !(element === event.target || element.contains(event.target)) &&
                !(
                    excludeComponent &&
                    (event.target === excludeComponent || excludeComponent.contains(event.target))
                )
            ) {
                binding.value(event, element)
            }
        }

        handleShadow(element, (app) => {
            app.addEventListener('click', clickOutsideEvent, true)
        })

        if (!element._clickOutside) {
            element._clickOutside = {
                lastMousedownWasOutside: false,
            }
        }

        element._clickOutside[binding.instance.$.uid] = {
            clickOutsideEvent
        }
    },
    unmounted: function (element, binding) {
        if (!element._clickOutside) return

        handleShadow(element, (app) => {
            if (!app || !element._clickOutside?.[binding.instance.$.uid]) return

            const { clickOutsideEvent } = element._clickOutside[binding.instance.$.uid]
            app.removeEventListener('click', clickOutsideEvent, true)
        })

        delete element._clickOutside[binding.instance.$.uid]
    }
}

export default VClickOutside