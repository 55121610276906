<template>
    <div ref="viewModalRef" class="modal-content episode-modal">
        <div class="modal-header">
            <div class="row info-header">
                <div class="col">
                    <div
                        v-if="currentEpisodeSet && currentEpisodeSet > 1 && repCount < currentEpisodeSet"
                        class="rep-info fw-bold"
                    >
                        {{
                            $t('workout.exercise.form.view.repsIndication', {
                                current: repCount + 1,
                                total: currentEpisodeSet,
                            })
                        }}
                    </div>
                </div>
                <div class="col text-end">
                    <button class="btn btn-outline-tertiary rounded-pill close-modal" @click="hideModal">
                        {{ $t('globals.exit') }}
                    </button>
                </div>
            </div>
        </div>
        <template v-if="viewOptions !== null">
            <div class="modal-body" @mousemove="handleOverlay" @touchstart="handleOverlay" @touchmove="handleOverlay">
                <div class="d-inline-block w-100 h-100">
                    <VideojsPlayer
                        v-if="
                            item.content !== '' &&
                            item.content !== 'processing' &&
                            item.content !== 'pending' &&
                            item.content !== 'failed'
                        "
                        ref="playerRef"
                        :key="item.episode_id"
                        :options="videoOptions"
                        :autoplay="false"
                        @video-ended="videoEnded"
                        @video-playing="videoPlaying"
                        @video-can-play="videoCanPlay"
                    />

                    <div class="waiting" :class="{ loading: loading }">
                        <i class="fa-solid fa-spinner fa-spin-pulse"></i>
                    </div>
                    <div
                        v-if="waitingTime !== null && waitingTime > 0"
                        :key="`${item.id}-${repCount}`"
                        class="waiting-overlay position-absolute top-0 left-0 w-100 h-100 darken-overlay d-flex flex-column align-items-center justify-content-center"
                        :class="{ show: true }"
                    >
                        <circle-progress :total="totalWaitingTime" :value="waitingTime" />
                        <template v-if="waitPurpose === 'episode'">
                            <h1 class="fs-3 text-white">{{ $t('workout.exercise.form.view.nextEpisodeIn') }}</h1>
                        </template>
                        <template v-else-if="waitPurpose === 'repetition'">
                            <h1 class="fs-3 text-white">{{ $t('workout.exercise.form.view.nextRepIn') }}</h1>
                            <button class="btn btn-tertiary rounded-pill" @click="skipRestingRepeatTime">skip</button>
                        </template>
                        <template v-else>
                            <h1 class="fs-3 text-white">{{ $t('workout.exercise.form.view.takeABreak') }}</h1>
                        </template>
                    </div>
                </div>
                <div
                    v-if="item.extra?.prevEpisode || item.extra?.nextEpisode"
                    v-show="showOverlay"
                    class="general-overlay"
                    :class="{ show: showOverlay }"
                >
                    <div
                        class="position-absolute episode-overlay-row w-100"
                        :class="$breakpoints.smAndDown.value ? 'text-truncated' : ''"
                    >
                        <div v-if="item.extra?.prevEpisode" class="episode-overlay-left">
                            <a href="#" class="text-decoration-none" @click="playPrev(item.extra?.prevEpisode)">
                                <div class="d-flex align-items-center">
                                    <div class="prev-button me-3 text-white">
                                        <i class="fa fa-backward-step"></i>
                                    </div>
                                    <div
                                        class="prevTitle text-truncate text-white"
                                        :class="$breakpoints.smAndDown.value ? 'fs-7' : 'fs-5'"
                                    >
                                        <span class="episode-title fw-bold">
                                            {{
                                                TranslationsHelper.getLocalisedContent(
                                                    item.extra.prevEpisode.title,
                                                    currentLocale
                                                )
                                            }}
                                        </span>
                                        <br />
                                        {{ $t('workout.exercise.form.list.exercise.label') }}
                                        {{ item.extra.prevEpisode.episode_number }}
                                        -
                                        {{ $filters.formatDuration(item.extra.prevEpisode.total_duration_in_seconds) }}
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div v-if="item.extra?.nextEpisode" class="episode-overlay-right">
                            <a href="#" class="text-decoration-none" @click="playNext(item.extra?.nextEpisode)">
                                <div class="d-flex align-items-center">
                                    <div
                                        class="prevTitle text-truncate text-white text-end"
                                        :class="$breakpoints.smAndDown.value ? 'fs-7' : 'fs-5'"
                                    >
                                        <span class="episode-title fw-bold">
                                            {{
                                                TranslationsHelper.getLocalisedContent(
                                                    item.extra.nextEpisode.title,
                                                    currentLocale
                                                )
                                            }}
                                        </span>
                                        <br />
                                        {{ $t('workout.exercise.form.list.exercise.label') }}
                                        {{ item.extra.nextEpisode.episode_number }}
                                        -
                                        {{ $filters.formatDuration(item.extra.nextEpisode.total_duration_in_seconds) }}
                                    </div>
                                    <div class="next-button ms-3 text-white">
                                        <i class="fa fa-forward-step"></i>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div v-show="showOverlay" class="modal-footer pb-3">
                <div class="content">
                    <h3 v-if="item.title">
                        {{ item.title }}
                    </h3>
                    <p v-if="item.subtitle">
                        {{ item.subtitle }}
                    </p>
                </div>
            </div>
        </template>
        <template v-else>
            <div class="modal-body position-relative">
                <img
                    v-if="item.cover"
                    :src="item.cover"
                    class="position-absolute top-0 left-0 w-100 h-100 object-fit-cover"
                    :class="resetModalContent === true ? 'hidden' : ''"
                    alt="video cover"
                />
                <img
                    v-else
                    src="@/assets/images/masterclass_placeholder.jpg"
                    class="position-absolute top-0 left-0 w-100 h-100 object-fit-cover"
                    :class="resetModalContent === true ? 'hidden' : ''"
                    alt="video cover"
                />
                <Form ref="optionFormRef" @submit="handleOptionFormSubmit">
                    <div
                        class="position-absolute top-0 left-0 w-100 h-100 d-flex flex-column darken-overlay text-white justify-content-center align-items-center"
                    >
                        <div class="flex-grow-1 d-flex flex-column justify-content-center align-items-center">
                            <div class="text-center" :class="resetModalContent === true ? 'hidden' : ''">
                                <div class="col-6 mx-auto fs-6 mb-5">
                                    {{
                                        TranslationsHelper.getLocalisedContent(
                                            item.extra.currentEpisode.content,
                                            currentLocale
                                        )
                                    }}
                                </div>
                                <div class="fw-bold text-tertiary text-center my-4 mb-5">
                                    {{ $t('workout.exercise.form.view.enterYourSet.label') }}
                                </div>
                                <BaseAmountInput
                                    name="repetition"
                                    root-class="fs-3 text-center"
                                    :model-value="item.extra.currentEpisode.set"
                                    :min="1"
                                    :step="1"
                                >
                                    <template #default="{ value }"
                                        >{{ value }} {{ $t('workout.exercise.form.view.times.label') }}
                                    </template>
                                </BaseAmountInput>
                                <div v-if="item.extra.currentEpisode.repetition > 0" class="col-6 mx-auto fs-6 mt-5">
                                    <span class="fw-bold text-tertiary"
                                        >{{ $t('workout.exercise.form.view.repetitions.label') }}:</span
                                    ><br />
                                    <span class="fs-3">{{ item.extra.currentEpisode.repetition }}</span>
                                </div>
                                <div
                                    v-if="item.extra.currentEpisode.required_equipments.length > 0"
                                    class="col-6 mx-auto fs-6 mt-5"
                                >
                                    <span class="fw-bold text-tertiary"
                                        >{{ $t('workout.exercise.form.view.requiredEquipments.label') }}:</span
                                    ><br />
                                    {{ item.extra.currentEpisode.required_equipments.join(', ') }}
                                </div>
                            </div>
                        </div>
                        <div class="d-flex flex-column align-items-center">
                            <!-- <div class="d-flex d-flex">
                                <BaseCheckbox
                                    id="repetition-autoplay"
                                    name="autoplay"
                                    :inline="true"
                                    :model-value="false"
                                />
                                <div class="flex-grow-1 ms-3">
                                    <div>
                                        <label for="repetition-autoplay" class="fw-bold">
                                            {{ $t('user.workouts.viewEpisode.autoPlay') }}
                                        </label>
                                    </div>
                                    <div>
                                        <label for="repetition-autoplay" class="small">
                                            {{ $t('user.workouts.viewEpisode.autoPlayTips') }}
                                        </label>
                                    </div>
                                </div>
                            </div> -->
                            <button type="submit" class="btn btn-light btn-lg my-4 w-100">
                                {{ $t('workout.exercise.form.view.button.ready.label') }}
                            </button>
                        </div>
                    </div>
                </Form>
            </div>
        </template>
    </div>
</template>

<script setup>
import { computed, onBeforeMount, onBeforeUnmount, ref, watch } from 'vue'
import router from '@/router'
import { string } from 'yup'
import { Form } from 'vee-validate'
import i18n from '@/translation'

import { useUserStore } from '@/stores/user'
import { useRegistrationCtaModalStore } from '@/stores/registrationCtaModal'
import { useViewModalStore } from '@/stores/viewModal'
import { useFormModalStore } from '@/stores/formModal'
import { storeToRefs } from 'pinia'

import VideojsPlayer from '@/components/VideojsPlayer.vue'
import BaseAmountInput from '@/components/Form/BaseAmountInput.vue'
import BaseCheckbox from '@/components/Form/BaseCheckbox.vue'
import CircleProgress from '@/components/CircleProgress.vue'
import ApiService from '@/services/ApiService'

import TranslationsHelper from '@/helpers/TranslationsHelper'

/** Locale **/
const { t } = i18n.global

/** Stores **/
const userStore = useUserStore()
const registerCtaStore = useRegistrationCtaModalStore()
const viewModalStore = useViewModalStore()
const formModalStore = useFormModalStore()
const { viewOptions } = storeToRefs(viewModalStore)

/** Props **/
const props = defineProps({
    item: {
        type: Object,
        required: true,
    },
})

/** Init parameters **/
const overlayTimeout = 2000 // in ms
const currentLocale = i18n.global.locale.value
let reasons = ref([])
let videoOptions = ref({
    autoplay: true,
    preload: 'auto',
    // fluid: true,
    html5: {
        vhs: {},
    },
    sources: [],
})
const showOverlay = ref(false)
const playerRef = ref(null)
const timeoutId = ref()
const waitingTime = ref(null)
const totalWaitingTime = ref(null)
let loading = ref(true)
const waitInterval = ref(null)
const repCount = ref(0)
const waitPurpose = ref(null)
const optionFormRef = ref(null)
const viewModalRef = ref(null)
let resetModalContent = ref(true)

/** Methods **/
const currentEpisodeSet = computed(() => {
    return viewOptions.value?.set ? viewOptions.value.set : props.item.extra.currentEpisode.set || 1
})

const handleOverlay = () => {
    showOverlay.value = true

    if (timeoutId.value) {
        clearTimeout(timeoutId.value)
    }

    timeoutId.value = setTimeout(() => {
        showOverlay.value = false
    }, overlayTimeout)
}

const videoEnded = () => {
    const status = props.item.extra.nextEpisode ? 'started' : 'done'

    updateUserSeriesProgress(props.item.extra.currentEpisode.id, status)

    repeatEpisode()
}

const videoPlaying = () => {
    loading.value = false

    waitingTime.value = null
    totalWaitingTime.value = null

    clearInterval(waitInterval.value)
    // clearInterval(window.waitingTimer)
}

const videoCanPlay = () => {
    loading.value = false

    waitingTime.value = null
    totalWaitingTime.value = null

    clearInterval(waitInterval.value)

    playerRef.value.start()

    // clearInterval(window.waitingTimer)
}

const hideModal = () => {
    return new Promise((resolve) => {
        if (props.item.extra.isPreview) {
            viewModalStore.hideFormModal()
            resolve(true)
            return
        }

        formModalStore.showFormModal({
            title: t('workout.exercise.form.view.message.confirmExit'),
            fields: [
                {
                    type: 'select',
                    name: 'reason',
                    label: t('workout.exercise.form.view.closeVideoReason.label'),
                    placeholder: t('workout.exercise.form.view.closeVideoReason.label'),
                    defaultValue: '',
                    required: true,
                    options: reasons.value,
                    rules: string().required(t('globals.validations.required')),
                },
            ],
            submit: t('workout.exercise.form.view.button.submitCloseReason.label'),
            cancel: t('workout.exercise.form.view.button.cancelCloseReason.label'),
            onSubmit: (params) => {
                if (!closeModal(params)) {
                    resolve(false)
                    return
                }
                resolve(true)
            },
            onCancel: () => {
                keepModal()
                resolve(false)
            },
        })
    })
}

function keepModal() {
    formModalStore.hideFormModal()
}

function closeModal(params) {
    if (params.reason === 'continue') {
        formModalStore.hideFormModal()
        return false
    }
    updateUserSeriesProgress(props.item.extra.currentEpisode.id, 'postponed', params.reason)
        .then(() => {
            formModalStore.hideFormModal()
            viewModalStore.hideFormModal()
        })
        .catch(() => {})
    return true
}

const playNext = (_episode) => {
    if (userStore.cannot('goto', _episode) && props.item.extra.nextEpisode.checked_visibility === 'subscriber') {
        closeModal('continue')
        registerCtaStore.showModal()
        // router.push({
        //     name: 'payment',
        //     params: {
        //         creatorSlug: userStore.selectedCreator.slug,
        //     },
        // })
        return false
    }

    resetModalContent.value = true
    repCount.value = 0
    if (waitInterval.value) {
        clearInterval(waitInterval.value)
    }

    loading.value = true
    if (!props.item.extra.nextEpisode) {
        return false
    }

    // updateUserSeriesProgress(props.item.extra.nextEpisode.id, 'started')

    viewOptions.value = null
    viewModalStore.viewEpisodeInList(props.item.extra.nextEpisode, false, props.item.extra.isPreview).then(() => {
        resetModalContent.value = false
    })
}

const playPrev = (_episode) => {
    if (userStore.cannot('goto', _episode) && props.item.extra.prevEpisode.checked_visibility === 'subscriber') {
        closeModal('continue')
        registerCtaStore.showModal()
        // router.push({
        //     name: 'payment',
        //     params: {
        //         creatorSlug: userStore.selectedCreator.slug,
        //     },
        // })
        return false
    }

    resetModalContent.value = true
    repCount.value = 0
    if (waitInterval.value) {
        clearInterval(waitInterval.value)
    }

    loading.value = true
    if (!props.item.extra.prevEpisode) {
        return false
    }

    // updateUserSeriesProgress(props.item.extra.prevEpisode.id, 'started')

    viewOptions.value = null
    viewModalStore.viewEpisodeInList(props.item.extra.prevEpisode, false, props.item.extra.isPreview).then(() => {
        resetModalContent.value = false
    })
}

const updateUserSeriesProgress = (episodeId, status, reason = null) => {
    if (props.item.extra.isPreview) {
        return new Promise((resolve) => resolve())
    }

    return ApiService.update('my/series', props.item.extra.currentEpisode.series_id, {
        series_episode_id: episodeId,
        status: status,
        reason: reason,
    })
}

const repeatEpisode = () => {
    repCount.value++

    if (repCount.value >= currentEpisodeSet.value) {
        if (!props.item.extra.nextEpisode) {
            viewModalStore.hideFormModal()
            return false
        }

        const restingEpisodeTime = props.item.extra.currentEpisode.resting_episode_time || 5 // default 5 seconds
        waitFor(
            restingEpisodeTime,
            () => {
                if (!viewOptions.value?.autoplay) {
                    showOverlay.value = true
                    waitingTime.value = null
                    return
                }
                playNext()
            },
            'episode'
        )
        return
    }

    const restingRepeatTime = props.item.extra.currentEpisode.resting_repeat_time || 5 // default 5 seconds
    waitFor(
        restingRepeatTime,
        () => {
            playerRef.value.replay()
        },
        'repetition'
    )
}

function skipRestingRepeatTime() {
    playerRef.value.replay()
}

const waitFor = (durationInSeconds, fn, _waitPurpose = null) => {
    waitPurpose.value = _waitPurpose
    waitingTime.value = waitPurpose.value === 'episode' ? 1 : durationInSeconds
    totalWaitingTime.value = waitPurpose.value === 'episode' ? 1 : durationInSeconds

    waitInterval.value = setInterval(() => {
        if (waitingTime.value <= 0) {
            if (waitPurpose.value === 'episode') {
                resetModalContent.value = true
                playNext()
            }
            clearInterval(waitInterval.value)
            fn()
            return
        }
        waitingTime.value--
    }, 1000)
}

const handleOptionFormSubmit = (values) => {
    viewModalStore.setViewOptions({
        set: values.repetition,
        autoplay: false,
    })

    // updateUserSeriesProgress(props.item.extra.currentEpisode.id, 'started')
}

/** Lifecycle **/
onBeforeMount(() => {
    // Reset viewOptions to show set
    viewOptions.value = null

    videoOptions.value.sources.push({
        src: props.item.content,
        type: 'application/x-mpegURL',
    })

    // updateUserSeriesProgress(props.item.extra.currentEpisode.id, 'started')

    ApiService.getAll(`enum/user-series-progress-reasons?for=workout`).then(({ data }) => {
        reasons.value = data
    })
    resetModalContent.value = false
})

watch(
    () => props.item,
    () => {
        // console.log('content changed', props.item.content)

        videoOptions.value = {
            ...videoOptions.value,
            sources: [
                {
                    src: props.item.content,
                    type: 'application/x-mpegURL',
                },
            ],
        }
    }
)

onBeforeUnmount(() => {
    if (waitInterval.value) {
        clearInterval(waitInterval.value)
    }
})

defineExpose({
    hideModal,
})
</script>

<style>
.hidden {
    display: none !important;
}
#form-modal .modal-footer button {
    flex-grow: 1;
    margin: 0.438rem 3.125rem;
}

.episode-modal .video-js {
    max-height: 100vh !important;
}

.episode-modal .plyr--video {
    position: inherit;
    margin: auto;
    width: 100%;
    height: 100%;
}

.episode-modal .vjs-control-bar,
.episode-modal .plyr__controls {
    z-index: 2000;
}

.modal-fullscreen .modal-content {
    padding: 0;
    background: black;
}

.modal-fullscreen .modal-header {
    padding: 1.5rem;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 11;
}

.modal-fullscreen .modal-header .close-modal {
    margin-left: auto;
    padding: 0.625rem 2.5rem;
}

.modal-fullscreen .modal-footer {
    position: absolute;
    bottom: 0;
    color: white;
    font-family: Poppins, sans-serif;
    font-size: 1rem;
    background: transparent linear-gradient(359deg, #000000 0%, #54545400 100%) 0% 0% no-repeat padding-box;
    opacity: 1;
    width: 100%;
    z-index: 0;
}

.modal-fullscreen .modal-footer .content {
    padding: 2.438rem 7.563rem;
    width: 100%;
}

.modal-fullscreen .modal-footer h3 {
    font-family: Poppins, sans-serif;
    font-size: 1.5rem;
}
</style>

<style lang="scss" scoped>
.episode-overlay-row {
    z-index: 2000;
    top: 70%;
}
.episode-overlay-left {
    float: left;
    background: #0000007f;
    padding: 1rem;
}
.episode-overlay-right {
    float: right;
    background: #0000007f;
    padding: 1rem;
}

// @media (max-width: 650px) {
.episode-overlay-left {
    min-width: 30%;
    max-width: 45%;
}
.episode-overlay-right {
    min-width: 30%;
    max-width: 45%;
}
// }

.episode-overlay.next-episode-overlay {
    position: absolute;
    top: 40%;
    right: 0;
}

.episode-overlay.prev-episode-overlay {
    position: absolute;
    top: 40%;
    left: 0;
    background: none;
    min-width: 0;
}

.general-overlay {
    opacity: 0;
}

.general-overlay.show {
    opacity: 1;
}

.general-overlay .episode-overlay {
    z-index: 2002;
}

.waiting {
    position: absolute;
    top: 50%;
    left: 50%;
    color: white;
    width: 200px;
    transform: translate(-50%, -50%);
    align-items: center;
    justify-content: center;
    z-index: 999;
    display: none;
}

.waiting.loading,
.waiting.show {
    display: flex;
}
.info-header {
    width: 100%;
}
.rep-info {
    color: white;
    max-width: 130px;
    text-align: center;
    background-color: black;
    padding: 0.625rem;
    border-radius: 3.125rem;
    align-items: center;
    justify-content: center;
}
.darken-overlay {
    background: rgba(0, 0, 0, 0.6);
}

.waiting-overlay {
    z-index: 2001;
}
</style>