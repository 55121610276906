<template>
    <BaseField
        :id="id"
        :name="name"
        :label="label"
        :error-message="errorMessage"
        :required="required"
    >
        <div class="select-wrapper">
            <VueMultiselect
                v-model="value"
                :name="name"
                :options="options"
                multiple
                label="label"
                track-by="name"
                :placeholder="placeholder"
                :close-on-select="false"
                :searchable="true"
                @input="handleChange"
                @blur="handleBlur"
            />
        </div>
    </BaseField>
</template>

<script setup>
import BaseField from '@/components/Modals/Fields/BaseField.vue'
import { toRef } from 'vue'
import { useField, Field } from 'vee-validate'

const props = defineProps({
    id: {
        type: String,
        default: '',
    },
    type: {
        type: String,
        default: 'text',
    },
    name: {
        type: String,
        required: true,
    },
    placeholder: {
        type: String,
        default: '',
    },
    label: {
        type: String,
        default: '',
    },
    errorMessage: {
        type: String,
        default: '',
    },
    rules: {
        type: Object,
        default: () => {},
    },
    options: {
        type: Object,
        default: () => {},
    },
    required: {
        type: Boolean,
        default: false,
    },
})

const name = toRef(props, 'name')
const { value, errorMessage, handleBlur, handleChange } = useField(
    name,
    props.rules
)
</script>
