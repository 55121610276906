<template>
    <BaseField
        :id="id"
        :name="name"
        :label="label"
        :error-message="errorMessage"
        :required="required"
    >
        <div class="select-wrapper">
            <VueSelect
                :id="id"
                v-model="value"
                class="form-control form-control-modal rounded-3"
                :class="[{ 'is-invalid': errorMessage }]"
                taggable
                :name="name"
                :value="value"
                :options="selectOptions"
                label="name"
                :reduce="(optionValue) => optionValue[resourceLabel]"
                @input="handleChange"
                @blur="handleBlur"
                @search="fetchOptions"
            >
                <template #no-options="{ searching }">
                    <template v-if="!searching">
                        {{ $t('tag.autocomplete.type') }}
                    </template>
                </template>
            </VueSelect>
        </div>
    </BaseField>
</template>

<script setup>
import BaseField from '@/components/Modals/Fields/BaseField.vue'
import VueSelect from 'vue-select'
import debounce from 'lodash.debounce'
import { ref, toRef } from 'vue'
import { useField } from 'vee-validate'
import 'vue-select/dist/vue-select.css'
import ApiService from '@/services/ApiService'

const props = defineProps({
    id: {
        type: String,
        default: '',
    },
    type: {
        type: String,
        default: 'text',
    },
    name: {
        type: String,
        required: true,
    },
    placeholder: {
        type: String,
        default: '',
    },
    label: {
        type: String,
        default: '',
    },
    errorMessage: {
        type: String,
        default: '',
    },
    rules: {
        type: Object,
        default: () => {},
    },
    options: {
        type: Object,
        default: () => {},
    },
    resourceModel: {
        type: String,
        default: '',
    },
    resourceParams: {
        type: Object,
        default: () => {},
    },
    resourceLabel: {
        type: String,
        default: 'name',
    },
    searchKey: {
        type: String,
        default: 'search',
    },
    required: {
        type: Boolean,
        default: false,
    },
})

const name = toRef(props, 'name')
const { value, errorMessage, handleBlur, handleChange } = useField(
    name,
    props.rules
)

const selectOptions = ref(props.options)

const fetchOptions = debounce((search) => {
    if (search.length > 0) {
        ApiService.getAll(props.resourceModel, {
            params: {
                ...props.resourceParams,
                ...{
                    [props.searchKey]: search,
                },
            },
        }).then(({ data }) => {
            selectOptions.value = data.data
        })
    }
}, 350)
</script>
<style>
:root {
    --vs-border-width: 0;
    --vs-dropdown-option-padding: 0.35rem 1.25rem;
    --vs-dropdown-option--active-bg: #e8c58650;
    --vs-dropdown-option--active-color: #2e2e30;
}
</style>