<template>
    <BaseField
        :id="id"
        :name="name"
        :label="label"
        :error-message="errorMessage"
        :required="required"
    >
        <div class="select-wrapper">
            <select
                :id="id"
                class="form-control form-control-modal rounded-3"
                :class="[{ 'is-invalid': errorMessage }]"
                :name="name"
                :value="value"
                @input="handleChange"
                @blur="handleBlur"
            >
                <option v-if="placeholder">{{ placeholder }}</option>
                <option
                    v-for="(optionName, keyName) in options"
                    :key="keyName"
                    :value="keyName"
                >
                    {{ optionName }}
                </option>
            </select>
        </div>
    </BaseField>
</template>

<script setup>
import BaseField from '@/components/Modals/Fields/BaseField.vue'
import { toRef } from 'vue'
import { useField } from 'vee-validate'

const props = defineProps({
    id: {
        type: String,
        default: '',
    },
    type: {
        type: String,
        default: 'text',
    },
    name: {
        type: String,
        required: true,
    },
    placeholder: {
        type: String,
        default: '',
    },
    label: {
        type: String,
        default: '',
    },
    errorMessage: {
        type: String,
        default: '',
    },
    rules: {
        type: Object,
        default: () => {},
    },
    options: {
        type: Object,
        default: () => {},
    },
    required: {
        type: Boolean,
        default: false,
    },
})

const name = toRef(props, 'name')
const { value, errorMessage, handleBlur, handleChange } = useField(
    name,
    props.rules
)
</script>