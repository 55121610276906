// import {initFirebaseMessagingRegistration} from "@/services/FirebaseService";
// import {useGlobalSettingsStore} from "@/stores/globalSettings";
// import WebPushService from '@/services/WebPushService'
import FcmNotificationService from "@/services/FcmNotificationService";
import {useGlobalSettingsStore} from "@/stores/globalSettings";
import NotificationService from "@/services/NotificationService";
import {useLoadingStore} from "@/stores/frontend/loading";

class SWService {
    static async SWRegistration(translator) {
        return this.checkAvailability()
            .then(() => {
                return this.registerServiceWorker(translator);
            })
            .then((swRegistration) => {
                // await WebPushService.initWebPush(swRegistration)
                return FcmNotificationService.initialize(swRegistration)
                // await initFirebaseMessagingRegistration(swRegistration)
                    // }
            })
            .catch(e => {
                console.log(e)
            })
    }

    static checkAvailability() {
        if (!('serviceWorker' in navigator)) {
            return Promise.reject('No Service Worker support!');
        }
        return Promise.resolve();
    }

    static async registerServiceWorker(translator) {

        let swRegistration = await navigator.serviceWorker.register(
            import.meta.env.MODE === 'production' ? '/sw.js' : '/dev-sw.js?dev-sw',
            {
                type: import.meta.env.MODE === 'production' ? 'classic' : 'module',
                scope: '/'
            }
        )
        await swRegistration.update();

        swRegistration.addEventListener('updatefound', () => {
            let newWorker = swRegistration.installing;

            newWorker.addEventListener('statechange', () => {
                switch (newWorker.state) {
                    case 'installed':
                        if (navigator.serviceWorker.controller) {
                            NotificationService.notifyUserConfirmation(
                                translator("globals.pwa.updateInfo"),
                                translator("globals.pwa.updateNow"),
                                translator("globals.pwa.updateLater")
                            )
                            .then(() => {
                                useLoadingStore().setLoading(true, true)
                                newWorker.postMessage({ type: 'sw/SKIP_WAITING' });
                            })
                            .catch((e) => {
                                if (e !== 'closed') {
                                    console.log(e)
                                }
                            })
                        }
                        break;
                    case 'redundant':
                        console.error('The installing service worker became redundant.');
                        break;
                }
            });
        });

        let refreshing;
        // The event listener that is fired when the service worker updates
        // Here we reload the page
        navigator.serviceWorker.addEventListener('controllerchange', function () {
            if (refreshing) return;
            window.location.reload();
            refreshing = true;
        });
        return swRegistration
    }

    static async requestNotificationPermission() {

        const permission = await window.Notification.requestPermission()

        if (permission !== 'granted') {
            return false
        }
        return permission
    }

}

export default SWService;