<template>
    <div class="position-relative">
        <svg
            :width="size"
            :height="size"
            viewBox="-25 -25 250 250"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            style="transform: rotate(-90deg)"
        >
            <circle
                r="90"
                :cx="originalRadius"
                :cy="originalRadius"
                fill="transparent"
                stroke="#e0e0e0"
                :stroke-width="baseWidth"
                :stroke-dasharray="circleLength"
                stroke-dashoffset="0"
            ></circle>
            <circle
                class="progress-circle"
                r="90"
                :cx="originalRadius"
                :cy="originalRadius"
                stroke="#ffffff"
                :stroke-width="progressWidth"
                stroke-linecap="round"
                :stroke-dashoffset="`${progressCircleLength}px`"
                fill="transparent"
                :stroke-dasharray="`${circleLength}px`"
                :style="{ transitionDuration: `${duration}s` }"
            ></circle>
        </svg>
        <div class="position-absolute top-0 left-0 w-100 h-100 d-flex align-items-center justify-content-center">
            <div class="text-white fw-bold fs-1">{{ value }}</div>
        </div>
    </div>
</template>

<script setup>
import { computed, nextTick, onBeforeMount, onMounted, ref } from 'vue'

const props = defineProps({
    size: {
        type: Number,
        default: 200,
    },
    value: {
        type: Number,
        default: 0,
    },
    total: {
        type: Number,
        default: 100,
    },
    baseWidth: {
        type: Number,
        default: 5,
    },
    progressWidth: {
        type: Number,
        default: 10,
    },
    textSize: {
        type: Object,
        default: () => {
            return {
                width: 50,
                height: 50,
                fontSize: '52',
            }
        },
    },
})

const originalRadius = computed(() => {
    return props.size / 2
})

const radius = computed(() => {
    return props.size / 2 - props.progressWidth
})

const circleLength = computed(() => {
    return 2 * Math.PI * radius.value
})

const progressCircleLength = ref()

const duration = computed(() => {
    return props.total
})

onBeforeMount(() => {
    progressCircleLength.value = circleLength.value
})

onMounted(() => {
    nextTick(() => {
        setTimeout(() => {
            progressCircleLength.value = 0
        }, 100)
    })
})
</script>

<style scoped>
.progress-circle {
    transition: stroke-dashoffset linear;
}
</style>
