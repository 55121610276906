<template>
    <div
        id="form-modal"
        class="modal modal-md fade"
        tabindex="-1"
        aria-hidden="true"
        aria-labelledby=""
    >
        <div class="modal-dialog">
            <div class="modal-content p-4">
                <div class="modal-header">
                    <img
                        src="/images/logo-form-modal.svg"
                        role="presentation"
                        class="form-modal-image"
                    />
                    <button class="form-modal-header-close" @click="hideModal">
                        <img
                            src="../../assets/images/close-icon.svg"
                            alt="Close"
                        />
                    </button>
                    <p class="form-modal-header-title">
                        {{ formModalStore.modal.title }}
                    </p>
                </div>
                <Form ref="myForm" @submit="onSubmit">
                    <div class="modal-body">
                        <RequiredInputGuide v-if="requiredTip" />
                        <div class="row">
                            <div
                                v-for="field in formModalStore.modal.fields"
                                :key="field.name"
                                class="col-12"
                                :class="[
                                    field.className
                                        ? field.className
                                        : 'col-lg-12',
                                ]"
                            >
                                <InputField
                                    v-if="['text', 'url', 'number', 'date'].includes(field.type)"
                                    :id="'form-modal-' + field.name"
                                    :type="field.type"
                                    :label="field.label"
                                    :name="field.name"
                                    :rules="schema[field.name]"
                                    :required="!!field.required"
                                    :max="field.max"
                                />
                                <TextareaField
                                    v-if="['textarea'].includes(field.type)"
                                    :id="'form-modal-' + field.name"
                                    :type="field.type"
                                    :label="field.label"
                                    :name="field.name"
                                    :rules="schema[field.name]"
                                    :rows="field.rows"
                                    :required="!!field.required"
                                    :max="field.max"
                                />
                                <SelectField
                                    v-if="field.type === 'select'"
                                    :id="'form-modal-' + field.name"
                                    :label="field.label"
                                    :name="field.name"
                                    :rules="schema[field.name]"
                                    :options="field.options"
                                    :required="!!field.required"
                                />
                                <MultiSelectField
                                    v-if="field.type === 'multiselect'"
                                    :id="'form-modal-' + field.name"
                                    :label="field.label"
                                    :name="field.name"
                                    :rules="schema[field.name]"
                                    :options="field.options"
                                    :required="!!field.required"
                                />
                                <AutocompleteField
                                    v-if="field.type === 'autocomplete'"
                                    :id="'form-modal-' + field.name"
                                    :label="field.label"
                                    :name="field.name"
                                    :rules="schema[field.name]"
                                    :options="field.options"
                                    :resource-model="field.resourceModel"
                                    :resource-params="field.resourceParams"
                                    :resource-label="field.resourceLabel"
                                    :search-key="field.searchKey"
                                    :required="!!field.required"
                                />
                                <MoneyField
                                    v-if="field.type === 'price'"
                                    :id="'form-modal-' + field.name"
                                    :label="field.label"
                                    :name="field.name"
                                    :rules="schema[field.name]"
                                    :required="!!field.required"
                                />
                                <CheckboxField
                                    v-if="field.type === 'checkbox'"
                                    :id="'form-modal-' + field.name"
                                    class-name="form-check form-switch"
                                    :label="field.label"
                                    :name="field.name"
                                    :rules="schema[field.name]"
                                    :required="!!field.required"
                                />
                                <FilepondField
                                    v-if="field.type === 'filepond'"
                                    :id="'form-modal-' + field.name"
                                    ref="filepondFieldRef"
                                    :accepted-file-types="field.acceptedFileTypes"
                                    :allow-multiple="field.allowMultiple"
                                    :label="field.label"
                                    :name="field.name"
                                    :rules="schema[field.name]"
                                    :error-message-force="field.errorMessageForce"
                                    :required="!!field.required"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">

                        <PrimaryButton v-if="formModalStore.modal.cancel" type="button" color="btn-outline-primary" @click="hideModal(true)">
                            {{ formModalStore.modal.cancel }}
                        </PrimaryButton>

                        <PrimaryButton v-if="!submitted">
                            {{ formModalStore.modal.submit }}
                        </PrimaryButton>
                        <span v-else class="btn btn-tertiary btn-lg disabled rounded-pill px-5">Saving...</span>

                    </div>
                </Form>
            </div>
        </div>
    </div>
</template>

<script setup>
import {computed, onUpdated, onMounted, ref} from 'vue'
import { Form } from 'vee-validate'
import { useFormModalStore } from '@/stores/formModal'
import { Modal } from 'bootstrap'
import PrimaryButton from '@/components/Buttons/PrimaryButton.vue'
import MoneyField from '@/components/Modals/Fields/MoneyField.vue'
import CheckboxField from '@/components/Modals/Fields/CheckboxField.vue'
import InputField from '@/components/Modals/Fields/InputField.vue'
import SelectField from '@/components/Modals/Fields/SelectField.vue'
import MultiSelectField from '@/components/Modals/Fields/MultiSelectField.vue'
import AutocompleteField from '@/components/Modals/Fields/AutocompleteField.vue'
import FilepondField from "@/components/Modals/Fields/FilepondField.vue";
import TextareaField from "@/components/Modals/Fields/TextareaField.vue";
import RequiredInputGuide from '@/components/Text/RequiredInputGuide.vue'

const formModalStore = useFormModalStore()
const myForm = ref(null)
const filepondFieldRef = ref(null)
const schema = ref({})
let currentModalObject = null
let submitted = computed(() => {
    return formModalStore.isSubmitted.value
})
let requiredTip = ref(false)

onUpdated(() => {
    requiredTip.value = (formModalStore.modal.fields.length > 0) ? true : false
})

onMounted(() => {
    currentModalObject = new Modal('#form-modal')
})

formModalStore.onDisplay((values, sentSchema) => {
    myForm.value.setValues(values)
    schema.value = sentSchema
    currentModalObject.show()
})

formModalStore.onHide(() => {
    currentModalObject.hide()
    formModalStore.setSubmitted(false);
})

const hasFilepond = computed(() => {
    return formModalStore.modal.fields.find(field => field.type === 'filepond')
})

function onSubmit(data) {
    formModalStore.setSubmitted(true);
    data = manageFilepond(data)
    formModalStore.modal.onSubmit(data)
}

function manageFilepond(data){
    if (hasFilepond.value) {
        const formData = new FormData()

        // TODO: Why do I need [0] ??
        let files = filepondFieldRef.value[0].getFiles()

        for (let i = 0; i < files.length; i++) {
            formData.append('file', files[i].file)
        }

        data = {
            ...data,
            [hasFilepond.value.name]: formData.get('file'),
        }
    }
    return data
}

function hideModal(cancelAction = false) {
    formModalStore.setSubmitted(false);
    currentModalObject.hide()
    if (cancelAction === true) {
        formModalStore.modal.onCancel()
    }
    formModalStore.hideFormModal()
}
</script>