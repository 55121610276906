<template>
    <BaseField :id="id" :name="name" :label="label" :class="rootClass">
        <div class="d-flex justify-content-center align-items-center" @blur="handleBlur">
            <a href="#" class="d-block px-4 btn-change-amount" @click.prevent="modify(-step)" @blur="handleBlur">
                <i class="fa fa-minus-circle"></i>
            </a>
            <div>
                <slot :value="value">
                    {{ value }}
                </slot>
            </div>
            <a href="#" class="d-block px-4 btn-change-amount" @click.prevent="modify(step)" @blur="handleBlur">
                <i class="fa fa-plus-circle"></i>
            </a>
        </div>
    </BaseField>
</template>

<script setup>
import BaseField from '@/components/Modals/Fields/BaseField.vue'
import { useField } from 'vee-validate'
import { toRef } from 'vue'

const props = defineProps({
    id: {
        type: String,
        default: '',
    },
    name: {
        type: String,
        required: true,
    },
    label: {
        type: String,
        default: '',
    },
    rootClass: {
        type: [String, Object],
        default: '',
    },
    modelValue: {
        type: Number,
        default: 1,
    },
    min: {
        type: Number,
        default: null,
    },
    max: {
        type: Number,
        default: null,
    },
    step: {
        type: Number,
        default: 1,
    },
})

const fieldName = toRef(props, 'name')

const { value, handleBlur, handleChange } = useField(fieldName, undefined, {
    initialValue: props.modelValue,
    syncVModel: true,
})

const modify = (delta = 1) => {
    const newValue = value.value + delta

    if (props.min !== null && newValue < props.min) {
        return
    }

    if (props.max !== null && newValue > props.max) {
        return
    }

    handleChange?.(newValue)
}
</script>

<style scoped>
.btn-change-amount {
    color: inherit;
    text-decoration: none;
}
</style>