<template>
    <BaseField
        :id="id"
        :name="name"
        :label="label"
        :error-message="errorMessage ?? errorMessageForce ?? ''"
        :required="required"
    >
        <file-pond
            ref="pond"
            :name="name"
            :label-idle="`${labelIdle}`"
            :label-file-type-not-allowed="`${messageFileNotAllowed}`"
            :allow-multiple="allowMultiple"
            :accepted-file-types="`${acceptedFileTypes}`"
            instant-upload="false"
            :files="myFiles"
        />
    </BaseField>
</template>

<script setup>
import BaseField from '@/components/Modals/Fields/BaseField.vue'
import {computed, ref, toRef} from 'vue'
import { useField } from 'vee-validate'

// Import Vue FilePond
import vueFilePond from 'vue-filepond'
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.esm.js'
import 'filepond/dist/filepond.min.css'
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css'
import {useI18n} from "vue-i18n";

const FilePond = vueFilePond(
    FilePondPluginFileValidateType,
    FilePondPluginImagePreview
)

const myFiles = []
const pond = ref(null)
const { t } = useI18n()

// Options
let labelIdle = t('creator.post.form.add.labelIdle')
let messageFileNotAllowed = t('creator.post.form.add.messageFileNotAllowed')

function getFiles() {
    // If file need to be uploaded
    if (pond.value !== undefined) {
        return pond.value.getFiles()
    }
    return undefined
}

defineExpose({
    getFiles
})

const props = defineProps({
    id: {
        type: String,
        default: '',
    },
    type: {
        type: String,
        default: 'text',
    },
    name: {
        type: String,
        required: true,
    },
    placeholder: {
        type: String,
        default: '',
    },
    label: {
        type: String,
        default: '',
    },
    errorMessageForce: {
        type: String,
        default: '',
    },
    acceptedFileTypes: {
        type: String,
        default: 'image/*',
    },
    allowMultiple: {
        type: Boolean,
        default: false,
    },
    rules: {
        type: Object,
        default: () => {},
    },
    required: {
        type: Boolean,
        default: false,
    },
})

const name = toRef(props, 'name')
const { value, errorMessage, handleBlur, handleChange } = useField(
    name,
    props.rules
)
</script>
